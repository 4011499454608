import React from 'react';
import styles from '../../../pages/Category/Category.module.scss';

export const CategoryTitle = ({ categoryName, numberOfItems }) => {
  return (
    <div className={styles.CategoryNameWrapper}>
      <h1 className={styles.CategoryName}>{categoryName}</h1>
      <span> ({numberOfItems || 0})</span>
    </div>
  );
};
