import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';

const GET_ADVERTISEMENT_CARDS = gql`
  query ProductAdvertisement($categoryId: String!) {
    productAdvertisementCards(categoryId: $categoryId) {
      id
      width
      has_frame
      title
      subtitle
      title_underline
      title_color
      text
      text_color
      has_button
      button_text
      vertical_align
      vertical_align_value
      horizontal_align
      horizontal_align_value
      image_background
      image_top
      image_bottom
      gradient_color_from
      gradient_color_to
      is_link
      link
      position
      hide_title
    }
  }
`;

export class ProductAdvertisementQuery extends Query {
  static defaultProps = {
    query: GET_ADVERTISEMENT_CARDS
  };
}
