import { getFiltersData, SearchConsumer } from '@deity/falcon-front-kit';
import { I18n } from '@deity/falcon-i18n';
import { Box } from '@deity/falcon-ui';
import { CategoryArea, Loader } from '@deity/falcon-ui-kit';
import { NetworkStatus } from 'apollo-client';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useStateWithSsr } from '../../hooks';
import { Breadcrumbs } from '../../elements/Breadcrumbs/Breadcrumbs';
import { Filters } from '../../elements/Filters/Filters';
import { FiltersSummary } from '../../elements/Filters/FiltersSummary';
import { ProductAdvertisementQuery } from '../../elements/Product/ProductAdvertisement/ProductAdvertisementQuery';
import { ProductList } from '../../elements/ProductList/ProductList';
import Container from '../../layout/Container/Container';
import { CategoryWithProductListQueryExt } from '../../overrides/Category/CategoryWithProductsListQuery';
import { CustomerQuery, GET_CUSTOMER_GROUP_QUERY } from '../../overrides/Customer/CustomerQuery';
import { SEOFooter } from '../../sections/SEOFooter/SEOFooter';
import { ShowMore } from '../../ui/ShowMore/ShowMore';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { ItemListTracking, ScrollTracking, CategoryTracking } from '../../elements/Tracking/GoogleTagManager';
import useCheckBreakpoints from '../../hooks/CheckBreakpoint';
import { BodyElementAttribute } from '../../helpers/BodyElementAttribute/BodyElementAttribute';
import {
  CategoryHeaderImage,
  CategoryMeta,
  CategorySorting,
  CategoryTitle,
  CategoryOscItems
} from '../../elements/Category';
import { ProductListQuery } from '../../elements/Odoscope/ProductListQuery';
import { GoogleTagManager4OdoscopeTracking } from '../../elements/Tracking/GoogleTagManager/GA4/OdoscopeTracking';
import InfiniteLoader from './InfiniteLoader';
import styles from './Category.module.scss';
import OscInfiniteLoader from './OscInfiniteLoader';

const copy = item => item && JSON.parse(JSON.stringify(item));

const CategoryPage = withStoreConfiguration(({ storeConfiguration, id, path: urlPath }) => {
  const { isDesktop, isTablet } = useCheckBreakpoints();
  const scrollType = storeConfiguration?.catalog?.scrolling?.type;
  const categoryRef = useRef(null);
  const [manualScroll, setManualScroll] = useStateWithSsr(false, true);
  const [paginateSite, setPaginateSite] = useState(0);
  const [oscPaginateSite, setOscPaginateSite] = useState(1);
  const [loaderStatus, setLoaderStatus] = useStateWithSsr(true, false);
  const [passCategoryLoading] = useStateWithSsr(true, false);
  const [numberOfItems, setNumberOfItems] = useState(0);

  // Odoscope integration
  const [useOsc, setUseOsc] = useState(false);
  const oscSortingID = storeConfiguration?.asam_layoutconfiguration?.odoscope?.sorting_attribute || '';
  const oscPageSize = parseInt(storeConfiguration?.asam_layoutconfiguration?.odoscope?.page_size, 10) || 21;
  const oscData = CategoryOscItems({
    storeConfiguration,
    categoryID: id,
    limit: 9999,
    offset: 0
  });

  let plpLoaderClass = 'plpLoaderMobile';

  if (isDesktop) {
    plpLoaderClass = 'plpLoaderDesktop';
  }

  if (isTablet) {
    plpLoaderClass = 'plpLoaderTablet';
  }

  useEffect(() => {
    return () => {
      BodyElementAttribute({
        key: 'data-page-type',
        action: 'remove'
      });
    };
  }, []);

  return (
    <>
      {loaderStatus && (
        <Container>
          <div className="PageLoaderContainer">
            <div className={['PageLoader', plpLoaderClass].join(' ')} />
          </div>
        </Container>
      )}
      <SearchConsumer>
        {({ state }) => {
          if (oscSortingID === `${state?.sort?.field}:${state?.sort?.direction}`) {
            setUseOsc(true);
          } else {
            setUseOsc(false);
          }

          return (
            <CustomerQuery query={GET_CUSTOMER_GROUP_QUERY} ssr>
              {({ data: { customer } }) => (
                <CategoryWithProductListQueryExt
                  variables={{
                    categoryId: id,
                    customerGroupId: customer?.groupId || 0,
                    sort: state.sort,
                    filters: copy(state.filters),
                    path: urlPath
                  }}
                  passLoading={passCategoryLoading}
                >
                  {({ data: { category }, fetchMore, networkStatus, loading }) => {
                    if (!category) {
                      return <Loader />;
                    }
                    setLoaderStatus(false);

                    const { name, altName, productList, breadcrumbs, defaultSortBy } = category;
                    const { pagination, items, aggregations } = productList;
                    const filtersData = getFiltersData(state.filters, aggregations);
                    const categoryName = altName || name;

                    setNumberOfItems(pagination.totalItems);

                    if (defaultSortBy === oscSortingID && state?.sort === undefined) {
                      setUseOsc(true);
                    }

                    return (
                      <I18n>
                        {t => (
                          <React.Fragment>
                            {category.contentData && (
                              <CategoryMeta category={category} state={state} urlPath={urlPath} />
                            )}
                            {!loaderStatus && (
                              <React.Fragment>
                                <Container>
                                  {scrollType === 'autoscroll' && !manualScroll && !useOsc ? (
                                    <InfiniteLoader
                                      loading={loading || !pagination.nextPage || networkStatus !== NetworkStatus.ready}
                                      objectRef={categoryRef}
                                      fetchMore={fetchMore}
                                      manualScroll={manualScroll}
                                      setManualScroll={setManualScroll}
                                      paginateSite={paginateSite}
                                      setPaginateSite={setPaginateSite}
                                      storeConfiguration={storeConfiguration}
                                    />
                                  ) : null}
                                  <Breadcrumbs breadcrumbs={breadcrumbs.concat([{ name: categoryName, urlPath }])} />

                                  <div className={styles.Category} ref={categoryRef}>
                                    {loading && <Loader variant="overlay" />}
                                    <div className={styles.CategoryProductList}>
                                      {!!filtersData.length && (
                                        <div className={styles.Filters}>
                                          <Box gridArea={CategoryArea.filters}>
                                            <Filters data={filtersData} />
                                          </Box>
                                        </div>
                                      )}
                                      {category?.contentData?.header_container ? (
                                        <div className={styles.CategoryHeaderImage}>
                                          <CategoryHeaderImage category={category} />
                                        </div>
                                      ) : null}
                                      <div className={styles.CategoryProductListTop}>
                                        <CategoryTitle categoryName={categoryName} numberOfItems={numberOfItems} />
                                        {isDesktop ? <CategorySorting category={category} t={t} /> : null}
                                      </div>

                                      {!isDesktop ? <CategorySorting category={category} t={t} /> : null}
                                      <div>
                                        <FiltersSummary data={filtersData} />
                                      </div>

                                      {useOsc && oscData?.skus ? (
                                        <>
                                          <ProductListQuery
                                            variables={{
                                              filters: [
                                                {
                                                  field: 'sku',
                                                  value: oscData?.skus,
                                                  operator: 'eq'
                                                },
                                                ...copy(state.filters)
                                              ],
                                              perPage: oscPageSize
                                            }}
                                          >
                                            {({ data, fetchMore: oscFetch, loading: oscLoading }) => {
                                              if (!data || !data.productsList) {
                                                return null;
                                              }

                                              const { productsList } = data;
                                              const oscPagination = productsList?.pagination;

                                              setNumberOfItems(oscPagination.totalItems);
                                              return productsList?.items.length ? (
                                                <>
                                                  {scrollType === 'autoscroll' && !manualScroll ? (
                                                    <OscInfiniteLoader
                                                      loading={
                                                        oscLoading ||
                                                        !oscPagination.nextPage ||
                                                        networkStatus !== NetworkStatus.ready
                                                      }
                                                      objectRef={categoryRef}
                                                      fetchMore={oscFetch}
                                                      manualScroll={manualScroll}
                                                      setManualScroll={setManualScroll}
                                                      paginateSite={oscPaginateSite}
                                                      setPaginateSite={setOscPaginateSite}
                                                      storeConfiguration={storeConfiguration}
                                                    />
                                                  ) : null}

                                                  <ProductList products={productsList.items} />

                                                  {oscPagination.nextPage ? (
                                                    <ShowMore
                                                      onClick={() => {
                                                        ScrollTracking({
                                                          storeConfiguration,
                                                          automatically: false,
                                                          count: pagination.nextPage
                                                        });
                                                        setOscPaginateSite(oscPaginateSite + 1);

                                                        oscFetch();
                                                      }}
                                                    />
                                                  ) : null}
                                                </>
                                              ) : null;
                                            }}
                                          </ProductListQuery>
                                          {oscData?.skus?.length > 0 ? (
                                            <GoogleTagManager4OdoscopeTracking
                                              trackingId={oscData?.oscTracking}
                                              area={`category_page_${id}_tracking`}
                                            />
                                          ) : null}
                                        </>
                                      ) : (
                                        <>
                                          <ProductAdvertisementQuery variables={{ categoryId: id }}>
                                            {({ data: { productAdvertisementCards } }) => {
                                              return (
                                                <ProductList
                                                  products={items}
                                                  advertisementCards={productAdvertisementCards}
                                                  category={category}
                                                />
                                              );
                                            }}
                                          </ProductAdvertisementQuery>
                                          {pagination.nextPage && (
                                            <ShowMore
                                              onClick={() => {
                                                fetchMore();
                                                ScrollTracking({
                                                  storeConfiguration,
                                                  automatically: false,
                                                  count: pagination.nextPage - 1
                                                });
                                              }}
                                              loading={networkStatus === NetworkStatus.fetchMore}
                                            />
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </Container>
                                {category.contentData && (
                                  <SEOFooter
                                    headline={category?.contentData?.seo_footer_headline}
                                    full={category?.contentData?.seo_footer_fullwidth}
                                  />
                                )}
                                <ItemListTracking
                                  storeConfiguration={storeConfiguration}
                                  category={category}
                                  currentPage={pagination.currentPage}
                                  products={items.slice((pagination.currentPage - 1) * 21, items.length)}
                                  area="category_list"
                                  isCategory
                                />
                                {CategoryTracking({ storeConfiguration, products: items, category })}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                      </I18n>
                    );
                  }}
                </CategoryWithProductListQueryExt>
              )}
            </CustomerQuery>
          );
        }}
      </SearchConsumer>
    </>
  );
});

CategoryPage.propTypes = {
  id: PropTypes.string.isRequired
};

export default CategoryPage;
