import React, { useEffect } from 'react';
import { debounce } from 'lodash';
import { ScrollTracking } from '../../elements/Tracking/GoogleTagManager';

const OscInfiniteLoader = ({
  loading,
  objectRef,
  fetchMore,
  manualScroll,
  setManualScroll,
  paginateSite,
  setPaginateSite,
  storeConfiguration
}) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && !manualScroll && objectRef) {
      window.onscroll = debounce(() => {
        if (loading) return;
        if (
          objectRef.current &&
          window.innerHeight + document.documentElement.scrollTop >=
            objectRef.current.offsetTop + objectRef.current.offsetHeight - 250 &&
          window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight
        ) {
          if (paginateSite === 1) {
            setPaginateSite(2);
            fetchMore();
            ScrollTracking({ storeConfiguration, automatically: true, count: 2 });
          }
          if (paginateSite === 2) {
            setPaginateSite(3);
            fetchMore();
            ScrollTracking({ storeConfiguration, automatically: true, count: 3 });
          }
          if (paginateSite === 3) {
            setManualScroll(true);
          }
        }
      }, 200);
    }
  }, [fetchMore]);

  return <React.Fragment />;
};

export default OscInfiniteLoader;
