import { CategoryWithProductListQuery } from '@deity/falcon-shop-data';
import gql from 'graphql-tag';

export const GET_CATEGORY_PRODUCTS = gql`
  query CategoryProducts(
    $categoryId: ID!
    $customerGroupId: Int
    $sort: SortOrderInput
    $pagination: PaginationInput
    $filters: [FilterInput!]
    $path: String!
  ) {
    category: customerCategory(id: $categoryId, customerGroupId: $customerGroupId) {
      id
      name
      path
      altName
      breadcrumbPath
      breadcrumbs(path: $path) {
        name
        urlPath
      }
      storeUrls {
        storeCode
        url
      }
      contentData {
        # property 'id' is not used in frontend, but needed for ssr cache
        id
        alt_name
        header_container
        seo_footer_left
        seo_footer_center
        seo_footer_right
        seo_footer_fullwidth
        meta_title
        meta_description
        index
        storeUrls {
          storeCode
          url
        }
      }
      availableSortBy
      defaultSortBy
      productList(input: { sort: $sort, pagination: $pagination, filters: $filters }) {
        items {
          id
          name
          shortName
          sku
          type
          price {
            regular
            special
            minPrice
            maxPrice
            minTier
          }
          productImage {
            default
            retina
            defaultWebp
            retinaWebp
          }
          urlPath
          volume
          popularity
          rating
          reviewsSummary {
            totalCount
            averageRating
          }
          baseprice
          isInStock
          shortDescription
          brand
          categoryIds
          categoryPath
          options {
            id
            label
            values {
              valueIndex
              label
              isInStock
              swatch {
                type
                value
              }
            }
          }
          labels {
            entityId
            text
            type
            percent
            isSale
            size
            rotated
            transparent
            badge
            images {
              image
            }
          }
          hasCustomOptions
          applicationType
          skintypeValue
          skincareneedsValue
          cosmeticlineValue
          subscriptionOffer {
            enabled
          }
        }
        pagination {
          currentPage
          totalItems
          nextPage
        }
        aggregations {
          field
          type
          buckets {
            value
            range
            count
            title
          }
          title
        }
      }
    }
  }
`;

export class CategoryWithProductListQueryExt extends CategoryWithProductListQuery {
  static defaultProps = { ...super.defaultProps, query: GET_CATEGORY_PRODUCTS, fetchPolicy: 'cache-first' };
}
