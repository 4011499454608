import { Image } from '@deity/falcon-ui';
import React from 'react';
import styles from '../../../pages/Category/Category.module.scss';
import { CmsButton } from '../../Cms/Modules/Components/CmsButton';
import useCheckBreakpoints from '../../../hooks/CheckBreakpoint';

export const CategoryHeaderImage = ({ category }) => {
  const { isDesktop } = useCheckBreakpoints();
  return (
    <React.Fragment>
      {category?.contentData?.header_container?.map((headerImage, index) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          key={`categHeaderImage_${index}`} // eslint-disable-line
          className={headerImage.fields.cta_link ? 'Interactive' : null}
          onClick={
            headerImage.fields.cta_link
              ? () => document.getElementById(`${headerImage.fields.image[0].sys.id}`).click()
              : null
          }
        >
          {isDesktop ? (
            <Image
              key={`header-${index}`} // eslint-disable-line
              className={styles.CategoryHeader}
              src={headerImage.fields.image[0].fields.file.url}
              alt={headerImage.fields.image[0].fields.file.fileName}
            />
          ) : (
            <Image
              key={`header-${index}`} // eslint-disable-line
              className={styles.CategoryHeader}
              src={
                headerImage.fields.image[1]
                  ? headerImage.fields.image[1].fields.file.url
                  : headerImage.fields.image[0].fields.file.url
              }
              alt={headerImage.fields.image[0].fields.file.fileName}
            />
          )}
          {headerImage.fields.cta_link ? (
            <CmsButton
              types="invisible"
              id={headerImage.fields.image[0].sys.id}
              absoluteLink={headerImage.fields.cta_link}
              buttonText={headerImage.fields.cta ? headerImage.fields.cta : 'more'}
            />
          ) : null}
        </div>
      ))}
    </React.Fragment>
  );
};
