import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const GET_PRODUCT_LIST = gql`
  query ProductsList($filters: [FilterInput!], $page: Int, $perPage: Int) {
    productsList(input: { filters: $filters, pagination: { page: $page, perPage: $perPage } }) {
      items {
        id
        sku
        name
        categoryIds
        productImage {
          retina
          default
          defaultWebp
          retinaWebp
        }
        urlPath
        volume
        popularity
        rating
        type
        reviewsSummary {
          totalCount
          averageRating
        }
        baseprice
        price {
          regular
          special
          minPrice
          maxPrice
          minTier
        }
        applicationType
        categoryPath
        brand
        hasCustomOptions
        cosmeticlineValue
        labels {
          text
          type
          percent
          isSale
          size
          rotated
          transparent
          badge
          images {
            image
          }
        }
        stock {
          isInStock
          qty
        }
      }
      pagination {
        currentPage
        totalItems
        nextPage
      }
    }
  }
`;

const customFetchMore = function fetchMore(data, apolloFetchMore) {
  return apolloFetchMore({
    variables: {
      page: data.productsList.pagination.nextPage
    },
    updateQuery: function updateQuery(prev, _ref) {
      const { fetchMoreResult } = _ref;

      if (!fetchMoreResult) {
        return prev;
      }

      return Object.assign({}, prev, {
        productsList: Object.assign({}, prev.productsList, {
          items: [].concat(prev.productsList.items, fetchMoreResult.productsList.items),
          pagination: Object.assign({}, fetchMoreResult.productsList.pagination)
        })
      });
    }
  });
};

export class ProductListQuery extends Query {
  static defaultProps = {
    query: GET_PRODUCT_LIST,
    fetchMore: customFetchMore
  };
}
