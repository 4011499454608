import React from 'react';
import { SortOrderPickerProvider } from '@deity/falcon-front-kit';
import { SortOrder } from '../../SortOrder/SortOrder';
import { Multiselect } from '../../../ui/Multiselect/Multiselect';
import useCheckBreakpoints from '../../../hooks/CheckBreakpoint';

export const CategorySorting = ({ category, t }) => {
  const { isDesktop } = useCheckBreakpoints();
  return (
    <React.Fragment>
      {isDesktop ? (
        <SortOrderPickerProvider>
          {sortOrderPickerProps => (
            <SortOrder
              availableSortBy={category.availableSortBy}
              defaultSortBy={category.defaultSortBy}
              {...sortOrderPickerProps}
            />
          )}
        </SortOrderPickerProvider>
      ) : (
        <SortOrderPickerProvider>
          {sortOrderPickerProps => {
            const content = (
              <SortOrder
                availableSortBy={category.availableSortBy}
                defaultSortBy={category.defaultSortBy}
                {...sortOrderPickerProps}
                optionList
              />
            );
            return (
              <Multiselect
                removePaddingOnMobile
                iconVariant="secondary"
                headers={[t('productList.sort.title')]}
                contents={[content]}
                blockContentEvents
              />
            );
          }}
        </SortOrderPickerProvider>
      )}
    </React.Fragment>
  );
};
