import { Helmet } from 'react-helmet-async';
import React from 'react';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { BodyElementAttribute } from '../../../helpers/BodyElementAttribute/BodyElementAttribute';

export const CategoryMeta = withStoreConfiguration(({ storeConfiguration, category, state, urlPath }) => {
  const stores = Object.values(JSON.parse(storeConfiguration?.asam_store?.language_switch?.external_systems || {}));
  const current = storeConfiguration?.web?.secure?.base_url || null;
  const shouldShowLink = category?.contentData && !category?.contentData?.index?.includes('NOINDEX');
  const isParam = !!state?.filters?.length || !!state?.sort?.field;

  BodyElementAttribute({
    key: 'data-page-type',
    value: 'category',
    action: 'set'
  });

  return (
    <Helmet>
      {category?.contentData?.meta_title && <title>{category.contentData.meta_title}</title>}
      {category?.contentData?.meta_title && <meta property="og:title" content={category.contentData.meta_title} />}
      {category?.contentData?.meta_description && (
        <meta name="description" content={category.contentData.meta_description} />
      )}
      {category?.contentData?.meta_description && (
        <meta property="og:description" content={category.contentData.meta_description} />
      )}
      {current && <meta property="og:url" content={`${current}${urlPath.slice(1)}`} />}
      {category?.contentData?.index && !isParam ? (
        <meta name="robots" content={category.contentData.index} />
      ) : (
        <meta name="robots" content="noindex" />
      )}
      {category?.contentData?.canonicalLink && !isParam && shouldShowLink && (
        <link rel="canonical" href={`${category.contentData.canonicalLink}`} />
      )}
      {!category?.contentData?.canonicalLink && current && !isParam && shouldShowLink && (
        <link rel="canonical" href={`${current}${urlPath.slice(1)}`} />
      )}
      {stores &&
        shouldShowLink &&
        !isParam &&
        category.storeUrls?.map(store => {
          const localeStore = stores.find(({ storecode }) => storecode === store.storeCode);
          return localeStore ? (
            <link
              key={`hrefLang-${localeStore.locale}`}
              rel="alternate"
              hreflang={localeStore.locale}
              href={store.url}
            />
          ) : null;
        })}
    </Helmet>
  );
});
