import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import OcapiAsambeauty from '../../../../../../../public/static/js/ocapi-asambeauty';

const isSSR = typeof window === 'undefined';

export const CategoryOscItems = ({ storeConfiguration, categoryID, limit, offset }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [odoscopeData, setOdoscopeData] = useState(false);

  const getOscCookieParam = cookieKey => {
    const oscCookie = Cookies.get(cookieKey);
    if (oscCookie) {
      return {
        [cookieKey]: oscCookie
      };
    }
    return {};
  };

  const getOdoscopeData = () => {
    const params = {
      categoryID,
      limit,
      offset,
      isShortAnswer: true
    };

    const odoscopeRequest = window.osc.categoryReco.getItems({
      ...params,
      ...getOscCookieParam(`osc-kWNWg`)
    });

    odoscopeRequest.then(data => {
      if (data?.goControl !== true) {
        setOdoscopeData(data);
      }
    });
  };

  if (parseInt(storeConfiguration?.asam_layoutconfiguration?.odoscope?.active, 10)) {
    useEffect(() => {
      const existingScript = document.getElementById('odoscopeSdk');

      if (!existingScript) {
        const script = document.createElement('script');
        script.src = OcapiAsambeauty;
        script.async = true;
        script.id = 'odoscopeSdk';
        script.onload = () => {
          setScriptLoaded(true);
          getOdoscopeData();
        };
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      }
    }, [categoryID]);

    if (!isSSR && scriptLoaded && odoscopeData) {
      const cookieConsent = Cookies.get('CookieConsent') || [];
      const skus = odoscopeData?.variants?.map(item => item.variantID);

      if (cookieConsent?.includes('essential:true')) {
        const oscCookieKey =
          odoscopeData?.oscCookie && !!Object.keys(odoscopeData.oscCookie).length
            ? Object.keys(odoscopeData.oscCookie)[0]
            : null;
        const oscCookieValue =
          odoscopeData?.oscCookie && !!Object.values(odoscopeData.oscCookie).length
            ? Object.values(odoscopeData.oscCookie)[0]
            : null;

        if (oscCookieKey) {
          Cookies.set(oscCookieKey, oscCookieValue, { expires: 30 });
        }

        return { skus, oscTracking: odoscopeData?.oscTracking };
      }

      return null;
    }
  }

  return null;
};
